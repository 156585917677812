// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H3 from "../../../../../styleguide/components/Heading/H3.res.js";
import * as H4 from "../../../../../styleguide/components/Heading/H4.res.js";
import * as React from "react";
import * as Button from "../../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../../styleguide/components/Control/Control.res.js";
import * as Js_math from "rescript/lib/es6/js_math.js";
import * as Keyboard from "../../../../../libs/Keyboard.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconDelete from "../../../../../styleguide/icons/IconDelete.res.js";
import * as IconSelect from "../../../../../styleguide/icons/IconSelect.res.js";
import * as IconHubSpoke from "../../../../../styleguide/icons/IconHubSpoke.res.js";
import * as IconSelectRevert from "../../../../../styleguide/icons/IconSelectRevert.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectWizardPanelScss from "./ProjectWizardPanel.scss";

var css = ProjectWizardPanelScss;

function reducer(_state, action) {
  if (action === "ShowDeleteConfirmation") {
    return "Shown";
  } else {
    return "Hidden";
  }
}

function ProjectWizardPanel$DeleteConfirmation$Control(props) {
  var show = props.onShow;
  return JsxRuntime.jsx(Control.make, {
              className: props.className,
              onClick: (function ($$event) {
                  $$event.stopPropagation();
                  show();
                }),
              children: JsxRuntime.jsx(IconDelete.make, {
                    size: "SM",
                    color: "LightGray"
                  })
            });
}

var Control$1 = {
  make: ProjectWizardPanel$DeleteConfirmation$Control
};

function ProjectWizardPanel$DeleteConfirmation$Dialog(props) {
  var cancel = props.onCancel;
  var $$delete = props.onDelete;
  if (props.state === "Shown") {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Are you sure?",
                              className: css.deleteConfirmationText
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(Button.make, {
                                      size: "SM",
                                      color: "Gray",
                                      onClick: (function (param) {
                                          cancel();
                                        }),
                                      children: "Cancel"
                                    }),
                                JsxRuntime.jsx(Button.make, {
                                      size: "SM",
                                      color: "Pink",
                                      onClick: (function (param) {
                                          $$delete();
                                        }),
                                      children: "Yes, delete it"
                                    })
                              ],
                              className: css.deleteConfirmationButtons
                            })
                      ],
                      className: css.deleteConfirmationDialog
                    }),
                className: css.deleteConfirmationOverlay,
                onClick: (function ($$event) {
                    $$event.stopPropagation();
                  })
              });
  } else {
    return null;
  }
}

var Dialog = {
  make: ProjectWizardPanel$DeleteConfirmation$Dialog
};

function useDeleteConfirmation(deleteControl) {
  var match = React.useReducer(reducer, "Hidden");
  var dispatch = match[1];
  var state = match[0];
  var ProjectWizardPanel$DeleteConfirmation = function (props) {
    if (deleteControl !== undefined) {
      return JsxRuntime.jsx(ProjectWizardPanel$DeleteConfirmation$Control, {
                  onShow: (function () {
                      dispatch("ShowDeleteConfirmation");
                    }),
                  className: props.className
                });
    } else {
      return null;
    }
  };
  var control = {
    make: ProjectWizardPanel$DeleteConfirmation
  };
  var ProjectWizardPanel$DeleteConfirmation$1 = function (props) {
    if (deleteControl !== undefined) {
      return JsxRuntime.jsx(ProjectWizardPanel$DeleteConfirmation$Dialog, {
                  state: state,
                  onDelete: deleteControl,
                  onCancel: (function () {
                      dispatch("HideDeleteConfirmation");
                    })
                });
    } else {
      return null;
    }
  };
  var dialog = {
    make: ProjectWizardPanel$DeleteConfirmation$1
  };
  return [
          control,
          dialog
        ];
}

var DeleteConfirmation = {
  reducer: reducer,
  initialState: "Hidden",
  Control: Control$1,
  Dialog: Dialog,
  useDeleteConfirmation: useDeleteConfirmation
};

function ProjectWizardPanel(props) {
  var __panelFooter = props.panelFooter;
  var toggleVisibility = props.toggleVisibility;
  var visibility = props.visibility;
  var panelFooter = __panelFooter !== undefined ? __panelFooter : false;
  var match = useDeleteConfirmation(props.deleteControl);
  var tmp;
  tmp = visibility === "Shown" ? JsxRuntime.jsx(IconSelectRevert.make, {
          size: "SM",
          color: "GrayText"
        }) : JsxRuntime.jsx(IconSelect.make, {
          size: "SM",
          color: "GrayText"
        });
  var tmp$1;
  tmp$1 = visibility === "Shown" ? css.panelHeaderWithBorder : css.panelHeaderWithoutBorder;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H3.make, {
                              className: css.panelHeaderTitle,
                              children: props.title
                            }),
                        tmp,
                        JsxRuntime.jsx(match[0].make, {
                              className: css.deletePanelControl
                            })
                      ],
                      className: Cx.cx([
                            css.panelHeader,
                            tmp$1
                          ]),
                      tabIndex: 0,
                      onKeyUp: (function ($$event) {
                          Keyboard.React.onSpaceOrEnter($$event, toggleVisibility);
                        }),
                      onClick: (function (param) {
                          toggleVisibility();
                        })
                    }),
                props.children,
                panelFooter ? JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(IconHubSpoke.make, {
                                      size: "MD",
                                      color: "DarkOrange"
                                    }),
                                className: css.panelFooterIcon
                              }),
                          JsxRuntime.jsx("div", {
                                children: "To create a hub-and-spoke click \"Add Another Location (Z)\" button above. To add a separate private line click \"Add another Private Line\" button below. Or upload a document with the list of locations and requirements at the bottom of the page.",
                                className: css.panelFooterText
                              })
                        ],
                        className: css.panelFooter
                      }) : null,
                JsxRuntime.jsx(match[1].make, {})
              ],
              className: css.panel
            });
}

function ProjectWizardPanel$Section(props) {
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsx(H4.make, {
                      className: css.sectionTitle,
                      children: props.title
                    }),
                props.children
              ],
              className: css.section
            });
}

var Section = {
  make: ProjectWizardPanel$Section
};

function ProjectWizardPanel$HeadlessSection(props) {
  return JsxRuntime.jsx("section", {
              children: props.children,
              className: css.section
            });
}

var HeadlessSection = {
  make: ProjectWizardPanel$HeadlessSection
};

function ProjectWizardPanel$HeadlessSectionWithDeleteControl(props) {
  var match = useDeleteConfirmation(props.deleteControl);
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsx(match[0].make, {
                      className: css.deleteSectionControl
                    }),
                props.children,
                JsxRuntime.jsx(match[1].make, {})
              ],
              className: css.section
            });
}

var HeadlessSectionWithDeleteControl = {
  make: ProjectWizardPanel$HeadlessSectionWithDeleteControl
};

function ProjectWizardPanel$CollapsableSection(props) {
  var tooltip = props.tooltip;
  var toggleVisibility = props.toggleVisibility;
  var visibility = props.visibility;
  var tmp;
  tmp = visibility === "Shown" ? JsxRuntime.jsx(IconSelectRevert.make, {
          size: "XXS",
          color: "GrayText"
        }) : JsxRuntime.jsx(IconSelect.make, {
          size: "XXS",
          color: "GrayText"
        });
  var children = JsxRuntime.jsxs(JsxRuntime.Fragment, {
        children: [
          JsxRuntime.jsx(H4.make, {
                className: css.sectionTitle,
                children: props.title
              }),
          tmp
        ]
      });
  var tmp$1;
  tmp$1 = visibility === "Shown" ? "" : css.sectionClosed;
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsx("header", {
                      children: JsxRuntime.jsx(Control.make, {
                            className: css.collapsableButton,
                            onClick: (function (param) {
                                toggleVisibility();
                              }),
                            children: tooltip !== undefined ? tooltip(children) : children
                          }),
                      className: css.collapsableSectionHeader
                    }),
                props.children
              ],
              className: Cx.cx([
                    css.section,
                    tmp$1
                  ])
            });
}

var CollapsableSection = {
  make: ProjectWizardPanel$CollapsableSection
};

function ProjectWizardPanel$ZebraGrid(props) {
  var children = props.children;
  var mobile = props.mobile;
  var items = props.items;
  var itemsPerRow = mobile ? 1 : 3;
  var totalItems = items.length;
  var numOfRows = mobile ? totalItems - 1 | 0 : Js_math.ceil(totalItems / itemsPerRow) - 1 | 0;
  return Belt_Array.mapWithIndex(Belt_Array.range(0, numOfRows), (function (index, param) {
                return JsxRuntime.jsx("div", {
                            children: Belt_Array.map(Belt_Array.slice(items, Math.imul(index, itemsPerRow), itemsPerRow), (function (item) {
                                    return children(item);
                                  })),
                            className: css.zebraRow
                          }, "row" + String(index));
              }));
}

var ZebraGrid = {
  make: ProjectWizardPanel$ZebraGrid
};

var make = ProjectWizardPanel;

export {
  css ,
  DeleteConfirmation ,
  make ,
  Section ,
  HeadlessSection ,
  HeadlessSectionWithDeleteControl ,
  CollapsableSection ,
  ZebraGrid ,
}
/* css Not a pure module */
